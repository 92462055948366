.alert {
  width: 100%;
}

.alert h3 {
  font-size: 15px;
  margin-left: 12px;
}

.alert.danger .alert-container {
  border: 2px solid var(--danger);
}
.alert.warning .alert-container {
  border: 2px solid var(--warning);
}
.alert-container {
  padding: 10px;
  border: 2px solid white;
}
.alert-container {
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.alert-body,
.alert-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.alert-body {
  margin-bottom: 10px;
}

.alert-body img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.alert-body-text {
  text-align: right;
  flex: 1;
  margin-left: 15px;
  font-size: 14px;
}

.alert-body-text h3 {
  color: var(--primary);
}
