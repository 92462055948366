.indicatorBar {
  width: 100%;
}
.indicatorBar-title {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 18px;
  min-height: 36px;
  display: flex;
  align-items: center;
  color: var(--primary);
}
.indicatorBar-body {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.indicatorBar-icon {
  text-align: center;
}
.indicatorBar-icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.indicatorBar-icon h4 {
  color: #666;
  font-size: 14px;
}

.indicatorBar-progress {
  margin: 0 15px;
  flex: 1;
}
.indicatorBar-progress-metrics-top {
  margin-bottom: 10px;
}
.indicatorBar-progress-bar {
  height: 8px;
  width: 100%;
  background: var(--primary);
  margin-top: 8px;
}

.indicatorBar-progress-metrics {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 16px;
  margin-top: 2px;
}

.indicatorBar-progress-metrics-point {
  position: absolute;
  top: calc(-10px - 9px - 2px);
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--primary);
}

.indicatorBar-information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  font-size: 10px;
  line-height: 1.3;
}

.indicatorBar-information-item h4 {
  color: #666;
}

.indicatorBar.disabled .indicatorBar-progress-bar {
  background: #bfbfbf;
}
