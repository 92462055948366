:root {
  --primary: #00706f;
  --danger: #ff0000;
  --warning: #ffbf00;
  --text: #666666;
  --primary: #00706f;
  --secondary: #008434;
  --success: #00d100;
  --successLighten: #adf1b1;
  --successDarken: #006800;
  --info: #ffe2c2;
  --warning: #ffcd00;
  --warningLighten: #ffe1c1;
  --warningDarken: #7f6600;
  --warningOrange: #c96800;
  --danger: #b7070d;
  --dangerLighten: #ffb3b5;
  --dangerDarken: #5b0306;
  --disabled: #666666;
  --disabledLighten: #f2f2f2;
  --disabledDarken: #333333;
  --infoDarken: #ff9a28;
  --care: #ff8027;
}

* {
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
}

input::-ms-reveal {
  display: none;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
}

.MuiInputAdornment-root button {
  padding: 0;
  color: black;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .PrivatePickersToolbar-root
  button {
  display: none;
}

.MuiModal-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .PrivatePickersToolbar-root {
  background-color: var(--primary);
  color: white;
}

.MuiModal-root .MuiDialog-container .MuiPaper-root .MuiTypography-root button {
  top: 1px;
  right: 2px;
  position: absolute;
}

.removeModalHeight {
  min-height: 0;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-root
  .MuiDialogContent-root
  .MuiBox-root
  .MuiFormControl-root {
  margin-top: 16px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-root
  form
  .MuiDialogContent-root
  .MuiBox-root {
  gap: 7px;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-root
  form
  .MuiDialogContent-root
  .MuiBox-root
  .MuiBox-root
  .MuiBox-root
  img {
  padding: 9px 0 0 0;
}

.MuiDialog-root
  .MuiDialog-container
  .MuiPaper-root
  form
  .MuiDialogContent-root
  .MuiBox-root
  .MuiBox-root
  .MuiBox-root
  .personalIdp
  img {
  padding: 0px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root {
  padding: 8px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root .MuiPaper-root {
  background-color: inherit;
}
