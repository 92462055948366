.indicatorIcon {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 15px 5px 15px 0;
  color: var(--text);
}

.icon {
  width: 110px;
  margin-left: -40px;
  margin-right: 10px;
  text-align: right;
}
.icon svg {
  font-size: 100px;
  width: 100px;
  height: 100px;
}

.texts {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.description {
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
}

.tableTitle {
  font-weight: 500;
  margin-bottom: 5px;
}
